import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../general/loader';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        items: [],
        loadCat: false,
        load: false,
        listview: "large",
        subCategories: 0,
        hideStartModal: false,
    }

    async componentDidMount(){

        // Get Items
        let id = -1; //window.location.pathname.replace("/menu/", "");

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/moon");
        this.setState({ categories: data }, ()=>{
            this.getCategoryName(id);
        });

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/items/moon");
            this.setState({ items: data }, ()=>{
                this.setState({loadCat: true});
                this.setState({load: true});
            });
        }

        this.layoutDirection();

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        if(id != -1)
            this.state.categories.map(cat => {
                if(cat.id == id) this.setState({ selectCategory: cat, subCategories: cat.subCategories[0].id});
            });
        else
            this.setState({ selectCategory: this.state.categories[0], subCategories: this.state.categories[0].subCategories[0].id });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    handleCategory = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true});
    }

    handleCategoryStartModal = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true, hideStartModal: true});
    }


    handleSubCategory = async catId => {
        this.setState({subCategories: catId});
    }

    handleListview = style => {
        this.setState({listview: style});
    }

    headCover = {'backgroundImage':`url('${this.settings.introCover}')`, 'backgroundSize':'cover', 'backgroundPosition':'center'}



    render() {

        return (
            <div className='menu-items'>
                <div className="head" style={this.headCover} >
                    <Link to={"/home"}>
                        <img src={backArrow} className="backward" alt="" />
                    </Link>
                    
                    <div className="language">
                        <button type='submit' className={this.props.Language == "krd"? 'activ': ''} onClick={() => this.handleChange('krd')}> کوردی </button>
                        <button type='submit' className={this.props.Language == "ar"? 'activ': ''} onClick={() => this.handleChange('ar')}> عربی </button>
                        <button type='submit' className={this.props.Language == "en"? 'activ': ''} onClick={() => this.handleChange('en')}> English </button>
                    </div>
                    <h2>Moon Pizza & <span>Doner</span> Restaurant</h2>
                </div>

                <div className='categories'>

                    <div>
                        {
                            this.state.loadCat ?
                            <> 
                                { this.state.categories.map(cat => <Category key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleCategory} Active = {this.state.selectCategory.id == cat.id} ></Category>) }
                            </>
                            : <Loader></Loader>
                        }
                    </div>
                    
                </div>

                {
                     (this.state.loadCat && this.state.selectCategory.subCat)
                    ? 
                        <div className="subcategories">
                            {
                                this.state.selectCategory.subCategories.map(
                                    cat => <Subcategory key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleSubCategory} Active = {this.state.subCategories == cat.id} />
                                )
                            }
                        </div>
                    :
                    ""
                }

                <div className='items' id='itemContainer'>

                <div className="head-listview">

                    <div className="listview">
                        <i role="button" className={`fas fa-th-list ${this.state.listview == "list"?'active':''}`} onClick={ () => this.handleListview("list")}></i>
                        <i role="button" className={`fas fa-th-large ${this.state.listview == "two"?'active':''}`} onClick={ () => this.handleListview("two")}></i>
                        <i role="button" className={`far fa-square ${this.state.listview == "large"?'active':''}`} onClick={ () => this.handleListview("large")}></i>
                    </div>

                </div>

                {
                    this.state.load ?
                    <> 
                        {
                            this.state.listview == "list" ? this.state.items[this.state.subCategories].map(item => <List key={item.id}  Data = {item} Language = {this.props.Language} ></List> )
                            : this.state.listview == "two" ? <div className='style-two'> { this.state.items[this.state.subCategories].map(item => <Two key={item.id}  Data = {item} Language = {this.props.Language} ></Two> ) } </div>
                            : <div className='style-large'> { this.state.items[this.state.subCategories].map(item => <Large key={item.id}  Data = {item} Language = {this.props.Language} ></Large> ) } </div>
                        }
                    </>
                    : <Loader></Loader>
                }
                </div>


                <div className={`start-modal ${this.state.hideStartModal?'start-modal-hide':''}`} style={{direction: this.props.Language != 'en'? 'rtl': 'ltr'}}>
                    
                    <div>
                        <header>
                            <p>
                                {
                                    this.props.Language == "en"? 'Please choose the restaurant you are currently at:'
                                    : this.props.Language == "krd"? 'خوارنگەهێ هەلبژێرە:'
                                    : 'يرجى اختيار المطعم الذي تتواجد فيه حاليًا:'
                                } 
                            </p>
                        </header>
                        <section>
                            {
                                this.state.loadCat ?
                                <> 
                                    { this.state.categories.map(cat => <Category key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleCategoryStartModal} Active = {this.state.selectCategory.id == cat.id} ></Category>) }
                                </>
                                : <Loader></Loader>
                            }
                        </section>
                    </div>

                </div>
                
            </div>
        );
    }
}

export default Menu;